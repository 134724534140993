import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from '@iconify/react';
import { Button, Card, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TEETH } from '../../../assets/data/teeth';
import EMPTY from '../../../assets/illustrations/illustration_empty_content.svg';
import Iconify from '../../../components/iconify';
import LoadingScreen from '../../../components/loading-screen';
import { getAllArticles } from '../../../redux/slices/article/ArticleThunk';
import { getSearchedOptions } from '../../../redux/slices/option/optionThunk';
import { getOnePatient } from '../../../redux/slices/patient/patientThunk';
import { insertFiles, insertNum, insertPatient, insertPhy, resetStep, setDentAsConfirmed, setDentAsNotConfirmed, setUpdate, } from '../../../redux/slices/prothese/protheseSlice';
import { getAllTypes } from '../../../redux/slices/type/typeThunk';
import { dispatch, useSelector } from '../../../redux/store';
import { formatDate } from '../../../utils/formatDate';
import EmptyProthese from './prothese/EmptyProthese';
import LeftOptionSections from './prothese/LeftOptionSections';
import MateriauxSection from './prothese/MateriauxSection';
import PriceSection from './prothese/PriceSection';
import ProtheseUserInfo from './prothese/ProtheseUserInfo';
import RightOptionsSection from './prothese/RightOptionsSection';
import TeethSection from './prothese/TeethSection';
import TreatmentDescription from './prothese/TreatmentDescription';
import UploadMultiple from './prothese/UploadMultiple';
import { attachementStyle, emptySection, handleDentconfirmation, treatmentSection } from './styles';
export default function PatientSecondStep({ selectedDent, setSelectedDent, num, setNum, phy, setPhy, }) {
    const [selectedType, setSelectedType] = useState('');
    const [selectedArticle, setSelectedArticle] = useState('');
    const [types, setTypes] = useState([]);
    const [showUpload, setShowUpload] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(0);
    const { id } = useParams();
    const _id = useSelector((state) => state?.patient?.onData?._id);
    const [icons, setIcons] = useState([]);
    useEffect(() => {
        if (id) {
            dispatch(getOnePatient({ id }));
        }
        dispatch(getAllArticles({ limit: 1000 }));
        dispatch(getAllTypes({ limit: 1000 }));
        dispatch(getSearchedOptions({ searchType: 'COLOR' }));
        if (_id)
            dispatch(insertPatient(_id));
        const loadIcons = async () => {
            const loadedIcons = await Promise.all(TEETH.map(async (tooth) => {
                return {
                    id: tooth.id,
                    icon: await tooth.icon,
                };
            }));
            setIcons(loadedIcons);
        };
        loadIcons();
    }, []);
    const patientData = useSelector((state) => state.patient);
    const test = useSelector((state) => state.dent);
    const files = test.files;
    const options = useSelector((state) => state.option.allOptions);
    const materiaux = useSelector((state) => state.material);
    const data = patientData.onData;
    const error = patientData.error;
    const description = test.description;
    const done = test.done;
    const filter = ['ESPACE_CIMENT', 'EPAISSEUR_MINIMALE'];
    const treatment = useSelector((state) => state.treatment);
    const [table, setTable] = useState([
        ["Type d'analyse :", 'Nom du fichier', 'Date'],
    ]);
    const { enqueueSnackbar } = useSnackbar();
    const handleRemoveFile = (inputFile) => {
        const filtered = files.filter((file) => file !== inputFile);
        dispatch(insertFiles(filtered));
    };
    useEffect(() => {
        const table = [["Type d'analyse :", 'Nom du fichier', 'Date']];
        files?.map((el) => {
            const row = [el?.path.split('.')[0], el?.path, formatDate(el?.lastModifiedDate)];
            table.push(row);
        });
        setTable(table);
    }, [files]);
    const current = test?.dents.filter((el) => el?.name === selectedDent?.toString())[0]?.confirmed
        ? 'contained'
        : 'outlined';
    const dent = test?.dents.filter((el) => el?.name === selectedDent?.toString())[0];
    return (_jsx(Box, { children: _jsx(Box, { display: 'flex', sx: { gap: '15px', width: '100%' }, children: error ? (_jsxs(Box, { sx: emptySection, children: [_jsx("img", { src: EMPTY, alt: "error", style: { margin: '0 auto' } }), _jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Patient introuvable ou quelque chose c'est mal pass\u00E9" })] })) : data && options ? (_jsxs(Stack, { width: '100%', children: [_jsxs(Box, { display: 'flex', sx: { gap: '15px' }, width: '100%', children: [_jsx(ProtheseUserInfo, { data: data }), _jsx(PriceSection, {})] }), _jsx(Stack, { width: '100%', children: _jsxs(Box, { display: 'flex', sx: { gap: '15px' }, width: '100%', paddingBottom: '15px', children: [_jsx(TeethSection, { selectedDent: selectedDent, setSelectedDent: setSelectedDent, setSelectedArticle: setSelectedArticle, setSelectedType: setSelectedType, setTypes: setTypes, setCurrentPrice: setCurrentPrice }), _jsx(Stack, { width: '80%', children: selectedDent ? (_jsxs(_Fragment, { children: [!done ? (_jsx(Stack, { children: _jsxs(Box, { display: 'flex', flexDirection: 'column', sx: { gap: '15px' }, children: [_jsx(LeftOptionSections, { options: options, filter: filter, selectedDent: selectedDent, types: types, setSelectedType: setSelectedType, selectedType: selectedType, setCurrentPrice: setCurrentPrice }), _jsx(MateriauxSection, { materiaux: materiaux, selectedDent: selectedDent }), _jsx(RightOptionsSection, { options: options, filter: filter, selectedDent: selectedDent, currentPrice: currentPrice })] }) })) : (_jsxs(_Fragment, { children: [_jsx(Card, { sx: { padding: '15px', marginTop: '15px' }, children: treatment.loading ? (_jsx(LoadingScreen, {})) : (_jsx(Box, { sx: treatmentSection, children: treatment?.oneData &&
                                                                treatment?.oneData?.dents?.map((el, index) => {
                                                                    return (_jsxs(Box, { sx: {
                                                                            display: 'flex',
                                                                            gap: '10px',
                                                                            justifyContent: 'start',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            borderRadius: '10px',
                                                                        }, children: [_jsxs(Box, { sx: {
                                                                                    display: 'flex',
                                                                                    gap: '0px',
                                                                                    justifyContent: 'center',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'center',
                                                                                    borderRadius: '10px',
                                                                                    cursor: 'pointer',
                                                                                    width: '100px !important',
                                                                                }, children: [_jsx(Box, { sx: {
                                                                                            padding: '10px 5px',
                                                                                            borderRadius: '20px',
                                                                                            width: '100px !important',
                                                                                        }, children: _jsx("img", { src: icons[Number(el?.name) - 1].icon, alt: "error", style: { margin: '0 auto' } }) }), _jsx(Typography, { variant: "h6", color: 'text.secondary', children: icons[Number(el?.name) - 1]?.id })] }), _jsxs(Box, { children: [_jsxs(Typography, { variant: "h6", color: 'text.secondary', sx: {
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            gap: '5px',
                                                                                        }, children: [_jsx(Box, { sx: {
                                                                                                    width: '30px',
                                                                                                    height: '30px',
                                                                                                    borderRadius: '50%',
                                                                                                    backgroundColor: el?.color,
                                                                                                    border: '0.5px solid lightgray',
                                                                                                } }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: el?.type?.code })] }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: el?.remarks })] })] }, index));
                                                                }) })) }), description && (_jsx(Card, { sx: { marginTop: '15px', padding: '15px', marginBottom: '15px' }, children: _jsxs(Typography, { color: 'warning', variant: "overline", sx: {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '5px',
                                                            }, children: [_jsx(Icon, { icon: "material-symbols:error", color: "orange", width: '20px' }), description] }) })), _jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            gap: '5px',
                                                            alignItems: 'center',
                                                            marginTop: '15px',
                                                        }, children: [data && (_jsx(Checkbox, { checked: num, sx: { width: 'fit-content' }, onClick: () => {
                                                                    setNum(!num);
                                                                    dispatch(insertNum(!num));
                                                                } })), _jsx(Typography, { variant: "subtitle1", color: 'text.secondary', children: "Empreinte Num\u00E9rique" })] }), _jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            gap: '5px',
                                                            alignItems: 'center',
                                                        }, children: [_jsx(Checkbox, { checked: phy, sx: { width: 'fit-content' }, onClick: () => {
                                                                    setPhy(!phy);
                                                                    dispatch(insertPhy(!phy));
                                                                } }), _jsx(Typography, { variant: "subtitle1", color: 'text.secondary', children: "Empreinte Physique" })] }), num && (_jsx(Box, { sx: { padding: '15px', marginTop: '15px', paddingBottom: '0px' }, children: _jsx(Typography, { variant: "h6", color: 'text.secondary', children: "Pieces jointes :" }) }))] })), !done ? (_jsxs(Box, { sx: handleDentconfirmation, children: [_jsx(Button, { variant: "outlined", color: "error", onClick: () => {
                                                            enqueueSnackbar('Traitement supprimé avec succès');
                                                            dispatch(setDentAsNotConfirmed({ name: selectedDent.toString() }));
                                                        }, children: "Effacer" }), _jsx(Button, { variant: current, color: "success", onClick: () => {
                                                            if (!dent?.material || !dent?.type) {
                                                                enqueueSnackbar('Veuillez confirmer la selection', {
                                                                    variant: 'error',
                                                                });
                                                            }
                                                            else if (!dent?.color) {
                                                                enqueueSnackbar('Veuillez confirmer la teinte', {
                                                                    variant: 'error',
                                                                });
                                                            }
                                                            else {
                                                                enqueueSnackbar('Traitement confirmé avec succès');
                                                                dispatch(setDentAsConfirmed({ name: selectedDent.toString() }));
                                                            }
                                                        }, children: "Ok" })] })) : (_jsx(_Fragment, { children: num && (_jsxs(Box, { sx: attachementStyle, children: [treatment?.oneData && (_jsx(UploadMultiple, { open: showUpload, currentFiles: files, onClose: () => {
                                                                setShowUpload(false);
                                                            } })), num &&
                                                            files?.length > 0 &&
                                                            files?.map((el, key) => {
                                                                return (_jsx(Box, { children: _jsxs(Stack, { spacing: 2, direction: "row", alignItems: "center", sx: {
                                                                            my: 1,
                                                                            px: 1,
                                                                            py: 0.75,
                                                                            borderRadius: 0.75,
                                                                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                                                                            flexWrap: 'wrap',
                                                                        }, children: [_jsx("a", { href: el?.preview, download: true, children: _jsx(Icon, { icon: "material-symbols:download", color: "black", width: '20px' }) }), _jsxs(Stack, { flexGrow: 1, sx: { minWidth: 0 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: el.path }), _jsx(Typography, { variant: "caption", sx: { color: 'text.secondary' }, children: el?.path })] }), _jsx(IconButton, { edge: "end", size: "small", onClick: () => handleRemoveFile(el), children: _jsx(Iconify, { icon: "eva:close-fill" }) })] }, key) }));
                                                            }), num && !treatment.loading && (_jsx(Button, { variant: "outlined", color: "success", onClick: () => {
                                                                setShowUpload(true);
                                                            }, sx: { marginTop: '8px', marginBottom: '8px' }, children: "Joindre une pi\u00E8ce jointe" }))] })) })), done && !treatment.loading && (_jsx(Box, { sx: {
                                                    padding: '30px',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }, children: _jsx(Button, { variant: "outlined", color: "error", onClick: () => {
                                                        dispatch(setUpdate());
                                                        dispatch(resetStep());
                                                    }, children: "Retourner" }) }))] })) : (_jsx(EmptyProthese, {})) })] }) }), _jsx(Box, { children: !done && _jsx(TreatmentDescription, {}) })] })) : (_jsx(LoadingScreen, {})) }) }));
}
